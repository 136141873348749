





































































import {
  defineComponent,
  reactive,
  PropType,
  computed,
} from "@vue/composition-api"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import ProjectFile from "@/models/ProjectFile"
import IconButton from "@/components/buttons/IconButton.vue"
import { projectsContainer } from "@/containers/ProjectsContainer"

export type NewFileState = "current" | "new" | "delete"

interface State {
  isLoading: boolean
  progressItems: Array<{ name: string; size: number; progress: number }>
}

const propsOptions = {
  value: {
    type: Array as PropType<Array<{ state: NewFileState; file: ProjectFile }>>,
  },
  currentFolder: {
    type: String,
  },
}

export default defineComponent({
  props: propsOptions,
  components: {
    DefaultButton,
    I18nFormattedMessage,
    IconButton,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isLoading: false,
      progressItems: [],
    })
    const {
      createFiles,
      getFiles,
      deleteFiles,
      getContext,
    } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const onUpload = () => {
      const file = document.getElementById("file-upload")
      if (file) {
        file.click()
        file.addEventListener(
          "change",
          async e => {
            state.isLoading = true
            const element = e.target as HTMLInputElement
            const inputValues: Array<File> = []
            const context = await getContext()
            const project = await getProjectAsync(context.entityId)
            try {
              if (element.files) {
                for (const file of element.files) {
                  inputValues.push(file)
                }
                if (element.files && props.currentFolder) {
                  for (const file of element.files) {
                    state.progressItems.push({
                      name: file.name,
                      size: file.size,
                      progress: 0,
                    })
                  }
                  await createFiles(
                    project.siteId,
                    props.currentFolder,
                    inputValues,
                    (f, p) => {
                      state.progressItems = state.progressItems.map(cf => {
                        return cf.name === f.name
                          ? Object.assign(cf, { progress: p })
                          : cf
                      })
                    }
                  )
                  state.progressItems = []
                  const currentFiles = await getFiles(
                    project.siteId,
                    props.currentFolder
                  )
                  const targets = currentFiles
                    .filter(
                      f =>
                        inputValues.some(pf => pf.name === f.name) &&
                        !props.value?.some(pf => pf.file.name === f.name)
                    )
                    .map(f => ({
                      state: "new" as NewFileState,
                      file: f,
                    }))
                  emit("input", (props.value ?? []).concat(targets))
                }
              }
            } finally {
              state.isLoading = false
              element.value = ""
            }
          },
          {
            once: true,
          }
        )
      }
    }

    const currentFiles = computed(() => {
      return props.value?.filter(
        v => !state.progressItems.some(p => p.name === v.file.name)
      )
    })

    const undoItem = (undoItem: { state: NewFileState; file: ProjectFile }) => {
      emit(
        "input",
        props.value?.map(f =>
          f.file.webUrl === undoItem.file.webUrl
            ? Object.assign(f, { state: "current" })
            : f
        )
      )
    }

    const deleteItem = async (deleteItem: {
      state: NewFileState
      file: ProjectFile
    }) => {
      if (deleteItem.state === "current") {
        // 現在追加済みの物は削除フラグをつける
        emit(
          "input",
          props.value?.map(f =>
            f.file.webUrl === deleteItem.file.webUrl
              ? Object.assign(f, { state: "delete" })
              : f
          )
        )
      } else if (deleteItem.state === "new") {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        // 新しく追加したものは削除
        await deleteFiles(project.siteId, [deleteItem.file])
        emit(
          "input",
          props.value?.filter(f => f.file.webUrl !== deleteItem.file.webUrl)
        )
      }
    }

    const onUploadClick = async () => {
      try {
        onUpload()
      } finally {
        state.isLoading = false
      }
    }

    return {
      state,
      currentFiles,
      onUploadClick,
      deleteItem,
      undoItem,
    }
  },
})
